/*Imports*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,500;0,700;1,400&family=Roboto:ital,wght@0,100;0,400;1,700&display=swap");

h1,
h2 {
  margin: 1em 0em 0.25em 0em;
  font-size: 1em;
  font-weight: 700;
}
.App-logo {
  width: 12em;
  pointer-events: none;
  padding-left: 2em;
  padding-top: 2.5em;
  padding-bottom: 2.5em;
}

.App {
  background-image: "./AdobeStock_219080081.jpeg";
  min-height: 500px;
  background-attachment: fixed;
  background-position: 25%, 50%;
  background-repeat: repeat-y;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.755);
}
section {
  padding: 0em 0em 0em 2em;
	box-sizing: border-box;
	width: 100%;
	width: auto;
}


 body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 200;

}
html{
	background-color: #1d1d1d;
}
.body-container {
  border-left-style: solid;
  border-left-width: thin;
  border-left-color: #D9B451;
  margin-left: 1em;
	max-width: 90%;
}

.footer {
  font-size: 0.65em;
  background-color: #1d1d1d;
	background-repeat: repeat-x;
  border-top-style: solid;
  border-top-color: #D9B451;
  padding: 2.5em 5em;
}
.footer-links {
  color: white;
  text-decoration: none;
  font-weight: 400;
}

div {
  word-spacing: 0.25em;
  line-height: 1.5em;
  font-family: "Montserrat", sans-serif;
}

.intext-link {
  color: #ada27c;
  text-decoration: none;
  font-weight: 500;
}

/*Form CSS*/
#contact_form {
  display: block;
	
}
#flexContainer {
	overflow: hidden;
}
ul {
  padding: 0em;
  list-style-position: outside;
  margin-left: 1em;
}
ul li {
  font-size: 0.55em;
}
ul li span {
  font-size: 1.8em;
}

input {
	width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  background: rgba(53, 53, 53, 0.65);
  color: white;
  border: none;
  border-bottom-style: solid;
  border-bottom-width: thin;
}
input[type="checkbox"] {
  width: 1.5em;
  margin-right: 0.25em;
}
textarea {
	width: 110%;
  background: rgba(53, 53, 53, 0.65);
  color: white;
  height: 5em;
  border: none;
  border-bottom-style: solid;
  border-bottom-width: thin;
}
.text-area::placeholder { padding: 1em;}

.permission_label_text {
  font-size: 0.75em;
  line-height: 1em;
  margin-bottom: 1em;
	width: auto;
}

.form-submit {
  background-color: white;
  border: none;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
  padding: 1em;
  letter-spacing: 0.2em;
}

#contact_submit {
  margin: 2em 0em 5em 0em;
  font-weight: bold;
}

/*Desktop Media Queries*/

@media only screen and (min-width: 1024px) {

/*2 columns on form*/
	.contact_form_div {
		column-count: 2;
  }
/*Wider left margin for border and compact page size*/
  .body-container {
		max-width: 85%;
		border-left-style: solid;
		border-left-width: thin;
	
		border-left-color: ##D9B451;
		margin-left: 5em;
  }

	input {
		width: 95%;
		padding: 1em;
		background: rgba(53, 53, 53, 0.65);
		color: white;
		border: none;
		border-bottom-style: solid;
		border-bottom-width: thin;
	}
	input[type="checkbox"] {
		width: 1.5em;
		margin-right: 0.25em;
	}
	textarea {
		width: 100%;
		background: rgba(53, 53, 53, 0.65);
		color: white;
		height: 5em;
		border: none;
		border-bottom-style: solid;
		border-bottom-width: thin;
	}
	.permission_label_text {
		font-size: 0.75em;
		line-height: 1em;
		margin-bottom: 1em;
	}
	
	.form-submit {
		width: 100%;
		background-color: white;
		border: none;
		color: black;
		padding: 1em;
		text-align: center;
		text-decoration: none;
		display: inline-block;
		margin: 4px 2px;
		cursor: pointer;
		border-radius: 16px;
		padding: 1em;
		letter-spacing: 0.2em;
	}

}